import {formatDate} from 'date-fns';
import {Linking, Platform} from 'react-native';

export const openTelephone = (number: number) => {
  let phoneNumber = '';
  if (Platform.OS === 'ios') {
    phoneNumber = `telprompt:${number}`;
  } else {
    phoneNumber = `tel:${number}`;
  }

  Linking.openURL(phoneNumber).catch(error => console.log('error', error));
};

export const openEmail = (email: string) => {
  Linking.openURL(`mailto:${email}`);
};

export const appendStorageBaseUrl = (url: string) => {
  return `https://api.breakbag.com/storage/${url}`;
};

export const populateDayNights = (days: number, nights: number) => {
  return `${nights} Night${nights > 1 ? 's' : ''} ${days} Day${
    days > 1 ? 's' : ''
  }`;
};

export const populateNights = (nights: number) => {
  return `${nights} Night${nights > 1 ? 's' : ''}`;
};

export const populateHours = (hours: number) => {
  return `${hours} Hour${hours > 1 ? 's' : ''}`;
};

export const formatCurrency = (money?: number | string) => {
  if (!money || (typeof money === 'string' && money.length == 0)) {
    return '';
  }
  const newMoney = typeof money === 'string' ? Number(money) : money;
  return (isNaN(newMoney) ? 0 : newMoney).toLocaleString('en-IN', {
    style: 'currency',
    currency: 'INR',
    unitDisplay: 'short',
    maximumFractionDigits: Platform.OS === 'android' ? undefined : 0,

    currencyDisplay: 'symbol',
  });
};

export const formatCurrencyWorklet = (money: string) => {
  'worklet';
  const newMoney = Number(money);
  return (isNaN(newMoney) ? 0 : newMoney).toLocaleString('en-IN', {
    style: 'currency',
    currency: 'INR',
    unitDisplay: 'short',
    maximumFractionDigits: Platform.OS === 'android' ? undefined : 0,
    currencyDisplay: 'symbol',
  });
};

export const splitEmail = (item: string) => item.split('@')[0];

export const getDateStringFromDates = (dates: string[]): string => {
  let formattedString = '';

  for (let index = 0; index < dates.length; index++) {
    const date = formatDate(dates[index], 'MMM dd');
    formattedString += `${date}, `;
  }
  return formattedString;
};

export type DateInfo = {
  minYear: number;
  maxYear: number;
  years: Record<
    string,
    {
      maxMonth: number;
      minMonth: number;
      months: Record<string, number[]>;
    }
  >;
};

export const getAvailableMinMax = (dates?: string[]): DateInfo => {
  if (!dates) {
    return {
      minYear: 0,
      maxYear: 0,
      years: {},
    };
  }

  const result: DateInfo = {
    minYear: Number.MAX_SAFE_INTEGER,
    maxYear: Number.MIN_SAFE_INTEGER,
    years: {},
  };
  dates.forEach(dateStr => {
    const [year, month, day] = dateStr.split('-').map(Number);

    // Update min and max year
    result.minYear = Math.min(result.minYear, year);
    result.maxYear = Math.max(result.maxYear, year);

    // Ensure the year object exists
    if (!result.years[year]) {
      result.years[year] = {
        minMonth: month, // Initialize minMonth with the current month
        maxMonth: month, // Initialize maxMonth with the current month
        months: {},
      };
    } else {
      // Update the minMonth and maxMonth if needed
      result.years[year].minMonth = Math.min(
        result.years[year].minMonth,
        month,
      );
      result.years[year].maxMonth = Math.max(
        result.years[year].maxMonth,
        month,
      );
    }

    // Ensure the month object exists for the current year
    if (!result.years[year].months[month]) {
      result.years[year].months[month] = [];
    }

    // Add the day to the corresponding month if not already present
    if (!result.years[year].months[month].includes(day)) {
      result.years[year].months[month].push(day);
    }
  });

  return result;
};
