import React, {type FC} from 'react';
import {View, type TextInputProps, TextInput, Platform} from 'react-native';
import Icon, {type IconTypes} from './Icon';
import {Colors} from '@/utils/colors';
import {Shadow} from '@/components/Shadow';
import {remapProps} from 'nativewind';

export type InputProps = TextInputProps & {
  containerClassName?: string;
  rightIcon?: IconTypes;
};

const RMapShadow = remapProps(Shadow, {
  className: 'style',
});

const cursorStyle = {cursor: 'pointer'} as any;

const Input: FC<InputProps> = props => {
  const {className, rightIcon, ...rest} = props;

  if (Platform.OS !== 'android') {
    return (
      <View
        style={{
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 5,
          },
          shadowOpacity: Platform.OS === 'ios' ? 0.54 : 0.24,
          shadowRadius: 6.27,
        }}
        className={`flex flex-row items-center w-full h-[50px] px-4 gap-1 bg-white rounded-[25px]  ${className}`}>
        {rightIcon ? (
          <View className="w-6 h-24 items-center justify-center">
            <Icon name={rightIcon} size={18} color={Colors.text} />
          </View>
        ) : null}
        <TextInput
          allowFontScaling={false}
          style={
            !rest.editable && Platform.OS === 'web' ? cursorStyle : undefined
          }
          className="flex flex-1 text-base font-sans font-normal outline-0 "
          {...rest}
        />
      </View>
    );
  }

  return (
    <RMapShadow
      startColor="#00000030"
      endColor="#00000000"
      offset={[0, 3]}
      distance={5}
      style={{
        borderRadius: 25,
        alignSelf: 'stretch',
        backgroundColor: '#fff',
        height: 50,
        flexDirection: 'row',
        alignItems: 'center',
        gap: 4,

        paddingHorizontal: 16,
      }}
      className={`flex flex-row items-center w-full h-[50px] px-4 gap-1 bg-white rounded-[25px] overflow-hidden ${className}`}>
      {rightIcon ? (
        <View className="w-6 h-24 items-center justify-center">
          <Icon name={rightIcon} size={18} color={Colors.text} />
        </View>
      ) : null}
      <TextInput
        allowFontScaling={false}
        className="flex flex-1 text-base font-sans font-normal outline-0 "
        {...rest}
      />
    </RMapShadow>
  );
};

export default Input;
