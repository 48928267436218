import React, {lazy} from 'react';
import {Platform} from 'react-native';

import HomeLoader from '@/components/Home/HomeLoader';
import TALoader from '@/components/TALoader';
import TourDetailsLoader from '@/components/TourDetails/TourDetailsLoader';
import {MainScreens, type MainStackParamList} from '@/types/navigation-types';
import withLazy from '@/utils/withLazy';
import {createStackNavigator} from '@react-navigation/stack';

const About = lazy(
  () => import(/* webpackChunkName: "About" */ '@/screens/About'),
);

const Tabs = lazy(
  () => import(/* webpackChunkName: "About" */ './TabNavigator.web'),
);

const TourDetails = lazy(
  () => import(/* webpackChunkName: "TourDetails" */ '@/screens/TourDetails'),
);
const Terms = lazy(
  () => import(/* webpackChunkName: "Terms" */ '@/screens/Terms'),
);
const PrivacyPolicies = lazy(
  () =>
    import(
      /* webpackChunkName: "PrivacyPolicies" */ '@/screens/PrivacyPolicies'
    ),
);
const Support = lazy(
  () => import(/* webpackChunkName: "Support" */ '@/screens/Support'),
);
const NotFound = lazy(
  () => import(/* webpackChunkName: "NotFound" */ '@/screens/NotFound'),
);
const AttractionDetails = lazy(
  () =>
    import(/* webpackChunkName: "Attractions" */ '@/screens/AttractionDetails'),
);
const Reviews = lazy(
  () => import(/* webpackChunkName: "TabNavigator" */ '@/screens/Reviews'),
);

// const Home = lazy(
//   () => import(/* webpackChunkName: "Home" */ '@/screens/Home'),
// );

const Profile = lazy(
  () => import(/* webpackChunkName: "Profile" */ '@/screens/Profile'),
);

const ActivityDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "ActivityDetails" */ '@/screens/ActivityDetails'
    ),
);

const MyBookings = lazy(
  () =>
    import(/* webpackChunkName: "ActivityDetails" */ '@/screens/MyBookings'),
);

const MainStack = createStackNavigator<MainStackParamList>();

const Destinations = lazy(
  () => import(/* webpackChunkName: "Destinations" */ '@/screens/Destinations'),
);

const DestinationDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "DestinationDetails" */ '@/screens/DestinationDetails'
    ),
);

const ContactUs = lazy(
  () => import(/* webpackChunkName: "ContactUs" */ '@/screens/ContactUs'),
);
const Hiring = lazy(
  () => import(/* webpackChunkName: "Hiring" */ '@/screens/Hirring'),
);
const Blogs = lazy(
  () => import(/* webpackChunkName: "Blogs" */ '@/screens/Blogs'),
);

const Search = lazy(
  () => import(/* webpackChunkName: "Search" */ '@/screens/Search'),
);

const BlogDetails = lazy(
  () => import(/* webpackChunkName: "BlogDetails" */ '@/screens/BlogDetails'),
);

const OrderDetails = lazy(
  () => import(/* webpackChunkName: "OrderDetails" */ '@/screens/OrderDetails'),
);

const Reels = lazy(
  () => import(/* webpackChunkName: "Reels" */ '@/screens/Reels'),
);

const GroupTours = lazy(
  () => import(/* webpackChunkName: "GroupTours" */ '@/screens/GroupTours'),
);

const Affiliation = lazy(
  () => import(/* webpackChunkName: "Affiliation" */ '@/screens/Affiliation'),
);

const GroupTourDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "GroupTourDetails" */ '@/screens/GroupTourDetails'
    ),
);
const CareerDetails = lazy(
  () => import(/* webpackChunkName: "Reels" */ '@/screens/CareerDetails'),
);

const Careers = lazy(
  () => import(/* webpackChunkName: "Reels" */ '@/screens/Careers'),
);

const ThankYou = lazy(
  () => import(/* webpackChunkName: "ThankYou" */ '@/screens/ThankYou'),
);

const MainNavigator = () => {
  return (
    <MainStack.Navigator
      screenOptions={{cardStyle: {flex: 1}}}
      // initialRouteName={MainScreens.AttractionDetails}
    >
      <MainStack.Screen
        options={{
          headerShown: false,
          title:
            'BreakBag | Book Customized International & Domestic Trip Packages',
        }}
        name={MainScreens.Tabs}
        component={withLazy(Tabs, HomeLoader)}
      />
      <MainStack.Screen
        options={{
          headerShown: false,
          title:
            'BreakBag | Book Customized International & Domestic Trip Packages',
        }}
        name={MainScreens.Profile}
        component={withLazy(Profile)}
      />
      <MainStack.Screen
        options={{headerShown: false, title: 'BreakBag | Tours'}}
        name={MainScreens.TourDetails}
        component={withLazy(TourDetails, <TourDetailsLoader />)}
      />
      <MainStack.Screen
        options={{
          headerShown: Platform.OS !== 'web',
          title: 'BreakBag | About',
        }}
        name={MainScreens.About}
        component={withLazy(About)}
      />
      <MainStack.Screen
        options={{
          headerShown: Platform.OS !== 'web',
          title: 'BreakBag | Terms And Conditions',
        }}
        name={MainScreens.Terms}
        component={withLazy(Terms, TALoader)}
      />
      <MainStack.Screen
        options={{
          headerShown: Platform.OS !== 'web',
          title: 'BreakBag | Privacy And Policies',
        }}
        name={MainScreens.PrivacyPolicies}
        component={withLazy(PrivacyPolicies)}
      />
      <MainStack.Screen
        options={{
          headerShown: Platform.OS !== 'web',
          title: 'BreakBag | Support',
        }}
        name={MainScreens.Support}
        component={withLazy(Support)}
      />
      <MainStack.Screen
        options={{
          headerShown: false,
          title: 'BreakBag | Attraction',
        }}
        name={MainScreens.AttractionDetails}
        component={withLazy(AttractionDetails)}
      />
      <MainStack.Screen
        options={{
          headerShown: Platform.OS !== 'web',
          title: 'BreakBag | Reviews',
        }}
        name={MainScreens.Reviews}
        component={withLazy(Reviews)}
      />
      <MainStack.Screen
        options={{
          headerShown: Platform.OS !== 'web',
          title: 'BreakBag | Activities',
        }}
        name={MainScreens.ActivityDetails}
        component={withLazy(ActivityDetails)}
      />
      <MainStack.Screen
        options={{
          headerShown: Platform.OS !== 'web',
          title: 'BreakBag | My Orders',
        }}
        name={MainScreens.MyBookings}
        component={withLazy(MyBookings)}
      />
      <MainStack.Screen
        options={{
          headerShown: false,
          title: 'BreakBag | Destinations',
        }}
        name={MainScreens.Destinations}
        component={withLazy(Destinations)}
      />

      <MainStack.Screen
        options={{
          headerShown: false,
          title: 'BreakBag | Destinations',
        }}
        name={MainScreens.DestinationDetails}
        component={withLazy(DestinationDetails)}
      />
      <MainStack.Screen
        options={{
          headerShown: false,
          title: 'BreakBag | Group Tours',
        }}
        name={MainScreens.GroupTourDetails}
        component={withLazy(GroupTourDetails)}
      />
      <MainStack.Screen
        options={{
          headerShown: false,
          title: 'BreakBag | Contact Us',
        }}
        name={MainScreens.Contact}
        component={withLazy(ContactUs)}
      />
      <MainStack.Screen
        options={{
          headerShown: false,
          title: 'BreakBag | We are hiring',
        }}
        name={MainScreens.Hiring}
        component={withLazy(Hiring)}
      />
      <MainStack.Screen
        options={{
          headerShown: false,
          headerBackTitle: 'Home',
          title: 'BreakBag | Group Tours',
        }}
        name={MainScreens.GroupTours}
        component={withLazy(GroupTours)}
      />
      <MainStack.Screen
        options={{
          headerShown: false,
          title: 'BreakBag | Blogs',
        }}
        name={MainScreens.Blogs}
        component={withLazy(Blogs)}
      />
      <MainStack.Screen
        options={{
          headerShown: false,
        }}
        name={MainScreens.Search}
        component={withLazy(Search)}
      />
      <MainStack.Screen
        options={{
          headerShown: false,
          title: 'BreakBag | Blogs',
        }}
        name={MainScreens.BlogDetails}
        component={withLazy(BlogDetails)}
      />
      <MainStack.Screen
        options={{
          headerShown: false,
          title: 'BreakBag | Orders',
        }}
        name={MainScreens.OrderDetails}
        component={withLazy(OrderDetails)}
      />
      <MainStack.Screen
        options={{
          headerShown: false,
          title: 'BreakBag | Reels',
        }}
        name={MainScreens.Reels}
        component={withLazy(Reels)}
      />

      <MainStack.Screen
        options={{
          headerShown: false,
          title: 'Work With Us | BreakBag',
        }}
        name={MainScreens.Careers}
        component={withLazy(Careers)}
      />

      <MainStack.Screen
        options={{
          headerShown: false,
          title:
            'BreakBag | Book Customized International & Domestic Trip Packages | Affiliation',
        }}
        name={MainScreens.CareerDetails}
        component={withLazy(CareerDetails)}
      />
      <MainStack.Screen
        options={{
          headerShown: false,
          title:
            'BreakBag | Book Customized International & Domestic Trip Packages | Affiliation',
        }}
        name={MainScreens.Affiliation}
        component={withLazy(Affiliation)}
      />
      <MainStack.Screen
        options={{
          headerShown: false,
          title: 'BreakBag | Support',
        }}
        name={MainScreens.NotFound}
        component={withLazy(NotFound)}
      />
      <MainStack.Screen
        options={{
          headerShown: false,
          title: 'BreakBag | Thank You',
        }}
        name={MainScreens.ThankYou}
        component={withLazy(ThankYou)}
      />
    </MainStack.Navigator>
  );
};

export default MainNavigator;
