import React, {useState, useRef, type FC} from 'react';
import {Blurhash} from 'react-blurhash';
import {
  View,
  type ImageResizeMode,
  type StyleProp,
  type ViewStyle,
} from 'react-native';
import Animated, {FadeIn, FadeOut} from 'react-native-reanimated';

type TImageLoaderProps = {
  src: any;
  alt: string;
  className: string;
  style: StyleProp<ViewStyle>;
  resizeMode: ImageResizeMode;
  'data-nimg'?: string;
};

const classes: Record<ImageResizeMode, string> = {
  cover: 'object-cover',
  contain: 'object-contain',
  repeat: 'object-repeat',
  stretch: 'object-fit',
  center: 'object-center',
};

const blurhash = 'LKN]Rv%2Tw=w]~RBVZRi};RPxuwH';

const ImageLoader: FC<TImageLoaderProps> = props => {
  const {src, alt, resizeMode, className, style, ...rest} = props;
  const [size, setSize] = useState<{w: number; h: number}>();
  const [loaded, setLoaded] = useState(false);
  const ref = useRef<View>(null);

  return (
    <View
      className={className}
      style={style}
      onLayout={({
        nativeEvent: {
          layout: {width, height},
        },
      }) => {
        setSize({w: width, h: height});
      }}
      // style={[style, {aspectRatio: `${aspectRatio}`, color: 'blue'}]}
      ref={ref}>
      <img
        {...rest}
        onLoad={() => {
          setLoaded(true);
        }}
        src={src}
        loading="lazy"
        width="100%"
        className={`w-full h-full ${
          classes[resizeMode || 'cover']
        } object-center`}
        alt={alt}
      />
      {loaded ? null : (
        <Animated.View
          entering={FadeIn}
          exiting={FadeOut}
          className="absolute left-0 right-0 bottom-0 top-0">
          <Blurhash
            hash={blurhash}
            width={size?.w || '100%'}
            height={size?.h || '100%'}
            resolutionX={64}
            resolutionY={64}
            punch={1}
          />
        </Animated.View>
      )}
    </View>
  );
};

export default ImageLoader;
