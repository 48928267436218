import {MainScreens, TabScreens} from 'types/navigation-types';

import type {LinkingOptions} from '@react-navigation/native';

export const linking: LinkingOptions<ReactNavigation.RootParamList> = {
  prefixes: ['http://localhost:8080/', 'http://127.0.0.1:5501/'],
  config: {
    screens: {
      [MainScreens.Tabs]: {
        screens: {
          [TabScreens.Home]: {
            path: '/',
          },

          [TabScreens.Profile]: '/customers/profile',
        },
      },
      [MainScreens.TourDetails]: {
        path: '/tours/:id',
      },
      [MainScreens.About]: {
        path: '/about',
      },
      [MainScreens.PrivacyPolicies]: {
        path: '/privacy-and-policies',
      },
      [MainScreens.Terms]: {
        path: '/terms-and-conditions',
      },
      [MainScreens.Contact]: {
        path: '/contact-us',
      },
      [MainScreens.Support]: {
        path: '/support',
      },
      [MainScreens.AttractionDetails]: {
        path: '/attractions/:id',
      },
      [MainScreens.ActivityDetails]: {
        path: '/activities/:id',
      },
      [MainScreens.Reviews]: {
        path: '/reviews',
      },
      [MainScreens.MyBookings]: {
        path: '/customers/orders',
      },
      [MainScreens.Destinations]: {
        path: '/destinations',
      },
      [MainScreens.DestinationDetails]: {
        path: '/destinations/:id',
      },
      [MainScreens.Blogs]: {
        path: '/blogs',
      },
      [MainScreens.Reels]: {
        path: '/reels',
      },
      [MainScreens.BlogDetails]: {
        path: '/blogs/:id',
      },
      [MainScreens.OrderDetails]: {
        path: '/customers/orders/:id',
      },
      [MainScreens.Search]: {
        path: '/search',
      },
      [MainScreens.GroupTours]: {
        path: 'group-tours',
      },
      [MainScreens.GroupTourDetails]: {
        path: 'group-tours/:id',
      },
      [MainScreens.Careers]: {
        path: 'careers',
      },
      [MainScreens.CareerDetails]: {
        path: 'careers/:id',
      },
      [MainScreens.Affiliation]: '/accreditation',
      [MainScreens.ThankYou]: '/thank-you',
      [MainScreens.NotFound]: '*',
    },
  },
};
