import React, {type FC} from 'react';
import {View, Text, Platform} from 'react-native';

import {logout} from '@/store/features/auth/authSlice';
import {dispatch} from '@/store/store';
import {MainScreens, TabScreens} from '@/types/navigation-types';
import type {IUser} from '@/types/user';
import {cn} from '@/utils/cn';
import {deleteAuth, removeData} from '@/utils/storageService';
import {splitEmail} from '@/utils/utils';
import {
  StackActions,
  TabActions,
  useNavigation,
} from '@react-navigation/native';
import Icon, {IconTypes} from './Icon';
import Menu from './Menu/Menu';
import {showToast} from './Toast/Toast';

type HeaderProfileMenuProps = {
  userDetails: IUser;
  textClassName?: string;
  tintColor?: string;
};

const HeaderProfileMenu: FC<HeaderProfileMenuProps> = props => {
  const {userDetails, textClassName, tintColor} = props;

  const navigation = useNavigation();

  return (
    <Menu
      onPressItem={async item => {
        if (item.id === 'my_profile') {
          if (Platform.OS === 'web') {
            navigation.dispatch(
              StackActions.push(MainScreens.Tabs, {
                initialRouteName: TabScreens.Profile,
              }),
            );
          } else {
            navigation.dispatch(StackActions.popToTop());
            navigation.dispatch(TabActions.jumpTo(TabScreens.Profile));
          }
          return;
        }

        if (item.id === 'my_bookings') {
          navigation.dispatch(StackActions.push(MainScreens.MyBookings));
          return;
        }

        await deleteAuth();
        await removeData('user-data');
        dispatch(logout());
        showToast('Logged out successfully');
        navigation.dispatch(StackActions.push(MainScreens.Tabs));
      }}
      menuTrigger={
        <View className="max-w-[120px] gap-2 flex-row items-center justify-between">
          <Text
            allowFontScaling={false}
            className={cn('text-white group-hover:text-primary', textClassName)}
            numberOfLines={1}>
            Hi, {userDetails.name || splitEmail(userDetails.email)}
          </Text>
          <Icon
            style={{transform: [{rotate: '-90deg'}]}}
            size={14}
            color={tintColor || '#fff'}
            name={IconTypes.ChevronLeft}
          />
        </View>
      }
      data={[
        {
          id: 'my_profile',
          name: 'My Profile',
        },
        {
          id: 'my_bookings',
          name: 'My Orders',
        },
        'line',
        {id: 'logout', name: 'Logout'},
      ]}
    />
  );
};

export default HeaderProfileMenu;
